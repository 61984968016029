(<template>
  <section class="ticket-statistics-charts">
    <div class="ticket-statistics-charts__bar">
      <div class="ticket-statistics__section-header">
        <h3 class="ticket-statistics__section-title">{{ $gettext('Overview') }}</h3>
      </div>
      <div class="ticket-statistics-charts__total-values total-values">
        <div>
          <span class="total-values__value total-values__value--blue">{{ totalCountTickets }}</span>
          <p class="total-values__text">{{ $gettext('Total tickets') }}</p>
        </div>
        <div>
          <span class="total-values__value total-values__value--orange">{{ openTicketsPercentage }}</span>
          <p class="total-values__text">{{ $gettext('Open tickets') }}</p>
        </div>
      </div>
      <component
        :is="barChartComponent.component"
        :options="barChartComponent.options"
        :chart-data="barChartComponent.chartData"
        class="ticket-statistics-charts__item" />
    </div>
    <div class="ticket-statistics-charts__doughnut">
      <div class="ticket-statistics__section-header">
        <h3 class="ticket-statistics__section-title">{{ $gettext('Tickets by topic') }}</h3>
      </div>
      <div class="ticket-statistics-charts__chart-wrapper">
        <component
          :is="doughnutChartComponent.component"
          :options="doughnutChartComponent.options"
          :chart-data="doughnutChartComponent.chartData"
          class="ticket-statistics-charts__item ticket-statistics-charts__item--doughnut" />
      </div>
    </div>
  </section>
</template>)

<script>
  import {mapState} from 'vuex';
  import BarChart from '@/components/statistics/charts/BarChart';
  import DoughnutChart from '@/components/statistics/charts/DoughnutChart';

  export default {
    components: {
      'doughnut-chart': DoughnutChart,
      'bar-chart': BarChart,
    },
    computed: {
      ...mapState('TicketSystemStore', {
        ticketsStatistics: (state) => state.ticketsStatistics || {}
      }),
      totalCountTickets() { return this.ticketsStatistics.totalCount; },
      openCountTickets() { return this.ticketsStatistics.openCount; },
      assignmentLabel() { return this.$gettext('Assignment'); },
      financeLabel() { return this.$gettext('Finance'); },
      otherLabel() { return this.$gettext('Other'); },
      openTicketsPercentage() {
        const total = this.totalCountTickets;
        const open = this.openCountTickets;
        if (total === 0) {
          return '0%';
        }
        const percentage = (open / total) * 100;
        return Math.round(percentage) + '%';
      },
      doughnutChartComponent() {
        return {
          component: 'doughnut-chart',
          options: {
            ...this.getChartOptions(),
            ...{
              legend: {
                position: 'bottom',
                labels: {
                  padding: 20,
                  boxWidth: 16,
                  fontStyle: 'bold',
                  fontSize: 14,
                  generateLabels: (chart) => {
                    const datasets = chart.data.datasets;

                    return datasets[0].data.map((data, i) => ({
                      text: `${chart.data.labels[i]} ${data}`,
                      fillStyle: datasets[0].backgroundColor[i],
                    }));
                  }
                }
              },
              cutoutPercentage: '75',
              scales: {xAxes: [{display: false}], yAxes: [{display: false}]}
            }
          },
          chartData: {
            labels: [this.assignmentLabel, this.financeLabel, this.otherLabel],
            datasets: [
              {
                data: [
                  this.ticketsStatistics.assignmentCount || 0,
                  this.ticketsStatistics.financeCount || 0,
                  this.ticketsStatistics.otherCount || 0
                ],
                backgroundColor: ['#246ddf', '#54bf54', '#fad623']
              }
            ]
          }
        };
      },
      barChartComponent() {
        return {
          id: Math.random(),
          component: 'bar-chart',
          options: {
            ...this.getChartOptions(true),
            ...{
              legend: {
                position: 'top'
              },
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true
                  }
                }],
              },
            }
          },
          chartData: this.getBarChartData(),
        };
      },
    },
    methods: {
      getChartOptions(xAxesLabel) {
        return {
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            mode: 'index',
            intersect: false,
            titleColor: '#FAD623'
          },
          hover: {
            mode: 'nearest',
            intersect: true
          },
          scales: {
            xAxes: [{
              display: true,
              scaleLabel: {
                display: true,
                labelString: xAxesLabel
              }
            }],
            yAxes: [{
              display: true,
              ticks: {
                stepSize: 1
              },
              scaleLabel: {
                display: true,
                labelString: 'Tickets count'
              }
            }]
          }
        };
      },
      getBarChartData() {
        const defaultDataset = (label, color) => ({
          label: this.$gettext(label),
          data: [0],
          backgroundColor: color,
          barThickness: 16,
          borderColor: color,
          borderWidth: 2,
          hoverBackgroundColor: color
        });

        if (!this.ticketsStatistics || !this.ticketsStatistics.statsByCreatedAt) {
          return {
            labels: ['No tickets'],
            datasets: [
              defaultDataset('Total', '#246dff'),
              defaultDataset('Open', '#f5771d')
            ]
          };
        }

        const mapStat = (key) => this.ticketsStatistics.statsByCreatedAt.map((item) => item[key]);

        return {
          labels: mapStat('groupName'),
          datasets: [
            {
              ...defaultDataset('Total', '#246dff'),
              data: mapStat('totalCount')
            },
            {
              ...defaultDataset('Open', '#f5771d'),
              data: mapStat('openCount'),
            }
          ]
        };
      }
    }
  };
</script>

<style scoped>
.ticket-statistics__section-title {
  color: var(--black-700);
  font-size: 18px;
}

.ticket-statistics-charts {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.ticket-statistics-charts__chart-wrapper {
  display: flex;
  justify-content: center;
}

.total-values {
  display: flex;
  gap: 35px;
  margin-top: 15px;
}

.total-values__value {
  position: relative;
  padding-left: 20px;
  color: var(--black-700);
  font-weight: bold;
  font-size: 24px;
}

.total-values__value::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  transform: translateY(-50%);
}

.total-values__value--blue::before {
  background-color: #246dff;
}

.total-values__value--orange::before {
  background-color: #f5771d;
}

.total-values__text {
  color: var(--black-500);
  font-size: 14px;
}

.ticket-statistics-charts__bar,
.ticket-statistics-charts__doughnut {
  padding: 20px 30px 30px 30px;
  background-color: var(--col-white);
}

.ticket-statistics-charts__bar {
  width: 63%;
}

.ticket-statistics-charts__doughnut {
  width: 35%;
}

.ticket-statistics-charts__item {
  width: 100%;
}

.ticket-statistics-charts__item--doughnut {
  max-width: 200px;
}
</style>
